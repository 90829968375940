<script setup lang="ts">
withDefaults(
  defineProps<{
    /**
     * Dark mode.
     */
    darkMode?: boolean | 'auto'
    /**
     * Text
     */
    text: string
    /**
     * Color scheme.
     */
    colorScheme: OzBadgeColorScheme
    /**
     * Size preset.
     *
     *
     * Determines the font-size
     */
    sizePreset?: OzBadgeSizePreset
  }>(),
  {
    darkMode: 'auto',
    sizePreset: OzBadgeSizePreset.Default,
  },
)
</script>

<script lang="ts">
export enum OzBadgeColorScheme {
  /**
   * **Bare**
   */
  Bare = 'Bare',
  /**
   * **Default**
   *
   * - Light mode: grey-700
   * - Dark mode: grey-200
   */
  Default = 'Default',
  /**
   * **Selection**
   *
   * - Light mode: grape-500
   * - Dark mode: canary-500
   */
  Selection = 'Selection',
  /**
   * **SelectionInverse**
   *
   * - Border: none
   * - Light mode: text-light-text-100 bg-grape-50
   * - Dark mode: text-dark-text-100 bg-canary-50
   */
  SelectionInverse = 'SelectionInverse',
  /**
   * **Disabled**
   *
   * - Light mode: dark-text-300
   * - Dark mode: light-text-300
   */
  Disabled = 'Disabled',
  /**
   * **DisabledInverse**
   *
   * - Border: none
   * - Light mode: text-light-text-300 bg-light-ui-300
   * - Dark mode: text-dark-text-300 bg-dark-ui-300
   */
  DisabledInverse = 'DisabledInverse',
}

export enum OzBadgeSizePreset {
  Bare = 'Bare',
  Default = 'Default',
  Tiny = 'Tiny',
}

export default {}
</script>

<template>
  <div
    :class="[
      'px-1 flex items-center',

      // Text
      sizePreset === OzBadgeSizePreset.Default && 'text-12-16 font-semibold',
      sizePreset === OzBadgeSizePreset.Tiny && 'text-body-tiny font-semibold',

      // Shape
      'rounded',

      // Colors
      colorScheme === OzBadgeColorScheme.Default && [
        // Text
        {
          'text-grey-700': darkMode === false,
          'text-grey-200': darkMode === true,
          'text-grey-700 dark:text-grey-200': darkMode === 'auto',
        },
        // Border
        {
          'border border-dashed': true,

          'border-grey-700': darkMode === false,
          'border-grey-200': darkMode === true,
          'border-grey-700 dark:border-grey-200': darkMode === 'auto',
        },
      ],
      colorScheme === OzBadgeColorScheme.Selection && [
        // Text
        {
          'text-grape-500': darkMode === false,
          'text-canary-500': darkMode === true,
          'text-grape-500 dark:text-canary-500': darkMode === 'auto',
        },
        // Border
        {
          'border border-dashed': true,

          'border-grape-500': darkMode === false,
          'border-canary-500': darkMode === true,
          'border-grape-500 dark:border-canary-500': darkMode === 'auto',
        },
      ],
      colorScheme === OzBadgeColorScheme.SelectionInverse && [
        // Text
        {
          'text-light-text-100': darkMode === false,
          'text-dark-text-100': darkMode === true,
          'text-light-text-100 dark:text-dark-text-100': darkMode === 'auto',
        },
        // Background
        {
          'bg-grape-500': darkMode === false,
          'bg-canary-500': darkMode === true,
          'bg-grape-500 dark:bg-canary-500': darkMode === 'auto',
        },
      ],
      colorScheme === OzBadgeColorScheme.DisabledInverse && [
        // Text
        {
          'text-dark-text-300': darkMode === false,
          'text-light-text-300': darkMode === true,
          'text-dark-text-300 dark:text-light-text-300': darkMode === 'auto',
        },
        // Background
        {
          'bg-light-ui-300': darkMode === false,
          'bg-dark-ui-300': darkMode === true,
          'bg-light-ui-300 dark:bg-dark-ui-300': darkMode === 'auto',
        },
      ],
      colorScheme === OzBadgeColorScheme.Disabled && [
        // Text
        {
          'text-dark-text-300': darkMode === false,
          'text-light-text-300': darkMode === true,
          'text-dark-text-300 dark:text-light-text-300': darkMode === 'auto',
        },
        // Border
        {
          'border border-dashed': true,

          'border-dark-text-300': darkMode === false,
          'border-light-text-300': darkMode === true,
          'border-dark-text-300 dark:border-light-text-300': darkMode === 'auto',
        },
      ],
    ]"
  >
    <slot name="startAdornment"></slot>
    <span>{{ text }}</span>
  </div>
</template>
